
import ValueSVG from '@/components/svg/values/ValueSVG.vue'

export default {
  components: { ValueSVG },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
}


import Value from '@/components/parts/values/Value.vue'
import ValueDetailSVG from '@/components/svg/values/ValueDetailSVG.vue'
export default {
  components: { Value, ValueDetailSVG },

  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tl: null,
      values: [this.section?.first && { value: this.section.first, subtitle: this.section?.firstBody }, this.section?.second && { value: this.section.second, subtitle: this.section?.secondBody }, this.section?.third && { value: this.section.third, subtitle: this.section?.thirdBody }],
    }
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    // const len = this.$refs.values.length
    if (this.$screen.lg) {
      this.tl = this.$gsap.timeline({
        scrollTrigger: {
          scrub: 0.6,
          trigger: this.$refs.valuesSection,
          // markers: true,
          // end: '+=200%',
          end: '+=200%',
          start: 'center center',
          pin: true,
          // pinSpacing: 'margin',
        },
      })
      this.tl.scrollTrigger.disable()
    }

    this.$screen.lg && this.tl.from(this.$refs.valuesSection, { scale: 0.95, opacity: 0.2, transformOrigin: 'center' }, '>')

    this.$refs.values.forEach((val, index) => {
      if (!this.$screen.lg) {
        this.tl = this.$gsap.timeline({
          scrollTrigger: {
            scrub: true,
            // trigger: this.$refs.valuesSection,
            // markers: false,
            start: 'center center',
            // end: '+=200%',
            pin: true,
            pinSpacing: 'margin',
            // pinSpacer: this.$refs.spacer,

            trigger: val.$el,
            // start: 'top 40%',
            // end: 'bottom 40%',
          },
        })
      }
      this.tl.to(val.$refs.text, { color: '#84F7A0' })
      this.tl.to([val.$refs.lSVG, val.$refs.rSVG], { opacity: 1 }, '<')
      this.tl.to(val.$refs.shadow, { opacity: 1 }, '<')
      this.tl.from(val.$refs.body, { opacity: 0 }, '>')
      // if (index + 1 < len) {
      this.tl.to(val.$refs.text, { color: 'transparent' })
      this.tl.to(val.$refs.shadow, { opacity: 0 }, '<')
      this.tl.to([val.$refs.lSVG, val.$refs.rSVG], { opacity: 0 }, '<')
      this.tl.to(val.$refs.body, { opacity: 0 }, '>')
      // }
    })

    this.$screen.lg && this.tl.to(this.$refs.valuesSection, { scale: 0.95, opacity: 0.2, transformOrigin: 'center' }, '>')
    this.$emit('sectionMounted')
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
